import React from 'react';

class PagingDots extends React.Component {
  getDotIndexes(slideCount, slidesToScroll, slidesToShow, cellAlign) {
    const dotIndexes = [];
    let lastDotIndex = slideCount - slidesToShow;

    switch (cellAlign) {
      case 'center':
      case 'right':
        lastDotIndex += slidesToShow - 1;
        break;
      default:
    }
    if (lastDotIndex < 0) {
      return [0];
    }

    for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
      dotIndexes.push(i);
    }
    dotIndexes.push(lastDotIndex);
    return dotIndexes;
  }

  getListStyles() {
    return {
      position: 'relative',
      margin: 0,
      top: -10,
      padding: 0,
    };
  }

  getListItemStyles() {
    return {
      listStyleType: 'none',
      display: 'inline-block',
    };
  }

  getButtonStyles(active, num) {
    return {
      background: '#FFFFFF',
      color: '#04585F',
      cursor: 'pointer',
      fontSize: 13,
      fontWeight: 'bold',
      borderRadius: '500em',
      border: '3px solid #04585F',
      width: 25,
      height: 25,
      lineHeight: '21px',
      opacity: active ? 1 : 0.5,
      marginLeft: num === 0 ? 0 : 25,
    };
  }

  render() {
    const indexes = this.getDotIndexes(
      this.props.slideCount,
      this.props.slidesToScroll,
      this.props.slidesToShow,
      this.props.cellAlign
    );
    return (
      <ul style={this.getListStyles()}>
        {indexes.map(index => {
          return (
            <li style={this.getListItemStyles()} key={index}>
              <div
                style={this.getButtonStyles(this.props.currentSlide === index, index)}
                onClick={this.props.goToSlide.bind(null, index)}
              >
                {index + 1}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default PagingDots;

import React from 'react';
import {
  Icon,
} from 'semantic-ui-react';

const defaultButtonStyles = disabled => ({
  border: 0,
  background: 'transparent',
  color: 'white',
  opacity: disabled ? 0.3 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  fontSize: 56,
});

export class SliderPreviousButton extends React.Component {
  constructor() {
    super(...arguments);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    event.preventDefault();
    this.props.previousSlide();
  }
  render() {
    const disabled =
      (this.props.currentSlide === 0 && !this.props.wrapAround) ||
      this.props.slideCount === 0;
    return (
      <div
        style={defaultButtonStyles(disabled)}
        disabled={disabled}
        onClick={this.handleClick}
        aria-label="previous"
      >
        <Icon name="chevron left"/>
      </div>
    );
  }
}

export default SliderPreviousButton;

import React from 'react';
import { Container, Grid, Button, Image, Header, Responsive } from 'semantic-ui-react';

import headerBackground from '../static/images/triangle-grey.svg';
import largeStudents from '../static/images/large-header.svg';
import MobileNavigation from './MobileNavigation';
import TabletDesktopNavigation from './TabletDesktopNavigation';
import cx from 'classnames';
import styled from 'styled-components';
import styles from './page.module.css';
import SignUpForm from './SignUpForm';

const Tagline = styled(Header)`
  color: #CD5B49;
  text-align: left;
  max-width: 520px;
  @media (min-width: 400px) {
    font-size: 40px;
  }

  @media (min-width: 700px) {
    margin-bottom: 40px;
    font-size: 50px;
  }
`;

const EarlyAccessBtn = styled(Button)`
  font-weight: 400 !important;

  @media (min-width: 400px) {
    font-size: 16px !important;
  }
`;

const BannerImage = styled(Image)`
  @media (max-width: 1023px) {
    max-height: 100vw;
  }
`

const HomepageHeading = ({
  text,
  mobile,
}) => (
  <div id="homepageHeading">
    <Container>
      <Responsive as={'div'} minWidth={1024}>
        <Grid stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8} className="tagLineCol" >
              <Tagline as='h3' className={styles.header}>
                { text }
              </Tagline>
              <SignUpForm trigger={
                <EarlyAccessBtn color="teal" className={cx(styles.btn, styles.signup)}>
                  Sign up for early access!
                </EarlyAccessBtn>
              } />
            </Grid.Column>
            <Grid.Column width={8} >
              <BannerImage src={largeStudents} alt="largeStudents"/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Responsive>
      <Responsive as={'div'} maxWidth={1023}>
        <Grid stackable verticalAlign='middle'>
          <Grid.Row className="bannerImageRow">
            <BannerImage src={largeStudents} />
          </Grid.Row>
          <Grid.Row className="tagLineRow">
            <Tagline as='h3' className={styles.header}>
              { text }
            </Tagline>
          </Grid.Row>
          <Grid.Row className="tagLineRow">
            <SignUpForm trigger={
              <EarlyAccessBtn color="teal" className={cx(styles.btn, styles.signup)}>
                Sign up for early access!
              </EarlyAccessBtn>
            } />
          </Grid.Row>
        </Grid>
      </Responsive>
    </Container>
  </div>
);

const HomeBackground = styled.div`
  background-color: #F4F4F4;
  background-image: url(${ headerBackground });
  background-size: 100%;
  background-position: center;
  padding-bottom: 80px;
  .bannerImageRow {
    justify-content: flex-end !important;
  }

  .tagLineCol {
    padding-right: 20px;
  }
  .tagLineRow {
    padding: 20px 4vw !important;
  }
`;

export const BFHeader = ({ text }) => (
  <HomeBackground> 
    <TabletDesktopNavigation />
    <MobileNavigation />
    <div />
    <HomepageHeading text={text} />
  </HomeBackground>
);

export default BFHeader;
export { HomeBackground, EarlyAccessBtn };

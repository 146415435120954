import React from 'react'
import Helmet from 'react-helmet'
import _ from 'lodash'
import Scrollspy from 'react-scrollspy'

import WhySegment from './WhySegment';
import HowItWorksSegment from './HowItWorksSegment';
import ParentEducatorSegment from './ParentEducatorSegment';
import ShoutoutSegment from './ShoutoutSegment';
import CompanyNewsSegment from './CompanyNewsSegment';

const getOneOf = (obj, paths, def) => {
  const foundPath = _.find(_.castArray(paths || []), (path) => _.has(obj, path));
  return foundPath ? _.get(obj, foundPath) : def;
}

const PageScrollSpy = () => {
  const [scrollSpyCb, setScrollSpyCb] = React.useState(null);
  const onUpdateScrollCb = React.useCallback((...args) => {
    if(_.get(scrollSpyCb, 'handler')) {
      scrollSpyCb.handler(...args)
    }
  }, [scrollSpyCb]);
  React.useEffect(() => {
    const sectionId = _.trimStart(window.location.hash, '#');
    if(sectionId) {
      setTimeout(() => {
        // eslint-disable-next-line
        const elmnt = document.getElementById(sectionId);
        if(elmnt && _.isFunction(elmnt.scrollIntoView)) {
            elmnt.scrollIntoView();
        }
      }, 500);  
    }
    // active scrollspy
    setTimeout(() => {
      setScrollSpyCb({
        handler: (ele) => {
          const sectionId = _.get(ele, 'id');
          if (sectionId) {
            // navigate(`/#${sectionId}`);
            const sectionHash = sectionId === 'why' ? '#' : `#${sectionId}`;
            // eslint-disable-next-line
            if(window.history.pushState) {
              // eslint-disable-next-line
              window.history.pushState(null, null, sectionHash);
            }
            else {
              // eslint-disable-next-line
              window.location.hash = sectionHash;
            }
          }
        }
      });
    }, 5000);  
  }, []);
  return (
    <Scrollspy
      items={ ['why', 'howitworks', 'parenteducator', 'shoutout', 'news'] }
      currentClassName="is-current"
      onUpdate={onUpdateScrollCb}
    >
    </Scrollspy>
  )
};

export const HomePageTemplate = ({ post, isPreview }) => {
  return (
    <div>
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
      </Helmet>
      <section id="why">
        <WhySegment
          text2={[
            _.get(post, 'frontmatter.whySegment.headerText'),
          ]}
          sections={_.castArray(_.get(post, 'frontmatter.whySegment.sections', []).map(section => ({
            src: getOneOf(section, ['src.childImageSharp.fluid.src', 'src.publicURL', 'src']),
            subtitle: _.get(section, 'subtitle'),
            description: _.get(section, 'description'),
          })))}
        />
      </section>
      {
        (_.get(post, 'frontmatter.howItWorksSegment') && !_.get(post, 'frontmatter.howItWorksSegment.disable')) && 
        <section id="howitworks">
          <HowItWorksSegment
            
            text={[
              _.get(post, 'frontmatter.howItWorksSegment.headerText'),
              _.get(post, 'frontmatter.howItWorksSegment.descriptionText'),
            ]}
            screenshots={_.castArray(_.get(post, 'frontmatter.howItWorksSegment.screenshots', []).map(item => ({
              screen: getOneOf(item, ['screen.childImageSharp.fluid.src', 'screen.publicURL', 'screen']),
              alt: getOneOf(item, ['alt']),
              subtitle: _.get(item, 'subtitle'),
              description: _.get(item, 'description'),
            })))}
            advices={_.castArray(_.get(post, 'frontmatter.howItWorksSegment.advices', []))}
          />
        </section>        
      }
      {
        _.get(post, 'frontmatter.parentEducatorSegment') && 
        <section id="parenteducator">
          <ParentEducatorSegment
            text={[
              _.get(post, 'frontmatter.parentEducatorSegment.headerText'),
              _.get(post, 'frontmatter.parentEducatorSegment.descriptionText'),
            ]}
            images={_.castArray(_.get(post, 'frontmatter.parentEducatorSegment.images', []).map(image => ({
              src: getOneOf(image, ['src.childImageSharp.fluid.src', 'src.publicURL', 'src']),
              title: _.get(image, 'title')
            })))}
            sections={_.castArray(_.get(post, 'frontmatter.parentEducatorSegment.sections', []))}
          />
        </section>
      }
      <section id="shoutout">
        <ShoutoutSegment/>
      </section>
      {
        _.get(post, 'frontmatter.newsSegment') && 
        <section id="news">
          <CompanyNewsSegment
            text={[
              _.get(post, 'frontmatter.newsSegment.headerText'),
              _.get(post, 'frontmatter.newsSegment.descriptionText'),
              _.get(post, 'frontmatter.newsSegment.detailText')
            ]}
            news={_.castArray(_.get(post, 'frontmatter.newsSegment.news', []).map(item => {
              return ({
                image: {
                  src: getOneOf(item, ['image.childImageSharp.fluid.src', 'image.publicURL', 'image.src', 'image']),
                  title: getOneOf(item, ['imageTitle']),
                },
                title: _.get(item, 'title'),
                excerpt: _.get(item, 'excerpt'),
                href: _.get(item, 'href'),
              })
            }))}
            featuredNews={{
              image: {
                src: getOneOf(post, [
                  'frontmatter.newsSegment.featuredNews.images.0.src.childImageSharp.fluid.src',
                  'frontmatter.newsSegment.featuredNews.images.0.src.publicURL',
                  'frontmatter.newsSegment.featuredNews.images.0.src',
                  'frontmatter.newsSegment.featuredNews.images.0',
                ]),
                title: getOneOf(post, [
                  'frontmatter.newsSegment.featuredNews.images.0.title',
                ]),
              },
              title: _.get(post, 'frontmatter.newsSegment.featuredNews.title'),
              excerpt: _.get(post, 'frontmatter.newsSegment.featuredNews.excerpt'),
              href: _.get(post, 'frontmatter.newsSegment.featuredNews.href'),
            }}
          />
        </section>
      }
      {
        !isPreview && <PageScrollSpy />
      }
    </div>
  )
}


export default HomePageTemplate;

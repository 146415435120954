import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  Grid,
  Image,
  Segment
} from 'semantic-ui-react';
import { SectionHeader } from './WhySegment';
import TechstarsLogo from '../../static/img/techstars-logo.png';
import NewVoices from '../../static/img/NV_logo_left.png';
import FourPtLogo from '../../static/img/4schools-logo.svg';
import TCLogo from '../../static/img/tc-logo.svg';
import AnitaB from '../../static/img/anitab-grayscale.png';
import GatesLogo from '../../static/img/gates-logo.svg';

const ShoutoutSubtitle = styled.p`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);

  @media (min-width: 787px) {
    font-size: 16px;
  }
`;

const ShoutoutSegment = ({
  text=[
    'You\'re in good company',
    'Our funders and supporters:'
  ]
}) => (
  <Segment style={{ padding: '5.7em 0em', background: '#424242', color:'white' }} vertical>
    <Grid container centered stackable verticalAlign='middle'>
      <Grid.Row>
        <SectionHeader as='h2' clr='#FFFFFF'>
          {text[0]}
        </SectionHeader>
      </Grid.Row>
      
      <Divider section style={{width: '75%', margin: '3em 0em'}} />

      <Grid.Row>
        <ShoutoutSubtitle> {text[1]} </ShoutoutSubtitle>
      </Grid.Row>

      <Grid.Row centered only='computer tablet' >
        <Image size='large' src={TechstarsLogo} alt="Techstars logo"/>
        <Divider hidden />
      </Grid.Row>

      <Grid.Row centered only='mobile' >
        <Grid.Column >
          <Image centered size='medium' src={TechstarsLogo} alt="Techstars logo"/>
          <Divider hidden />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns='equal'>
        <Grid.Column>
          <Image size='medium' src={GatesLogo} centered alt={`Bill & Melinda Gates Foundation logo`}/>
          <Divider hidden />
        </Grid.Column>

        <Grid.Column>
          <Image size='small' src={NewVoices} centered alt="New Voices Foundation logo"/>
          <Divider hidden />
        </Grid.Column>

          <Grid.Column>
            <Divider hidden />
            <Image size='medium' centered src={AnitaB} alt="AnitaB.org logo"/>
          </Grid.Column>
      </Grid.Row>

      <Grid.Row columns='equal'>
        <Grid.Column width={5}>
          <Divider hidden />
            <Image centered src={FourPtLogo} alt="4.0 Schools logo"/>
        </Grid.Column>

        <Grid.Column width={5}>
          <Divider hidden />
            <Image centered src={TCLogo} alt="Teachers College, Columbia University logo"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>  
);

export default ShoutoutSegment;
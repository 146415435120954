import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import HomePageTemplate from '../components/HomePageTemplate'

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <HomePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        post={post}
      />
    </HomeLayout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        whySegment {
          sections {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            subtitle
            description
          }
          headerText
        }
        howItWorksSegment {
          disable
          screenshots {
            screen {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            subtitle
            description
            alt
          }
          advices {
            author
            text
            organization
          }
          headerText
          descriptionText
        }
        parentEducatorSegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          sections {
            subtitle
            description
          }
          headerText
          descriptionText
        }
        newsSegment {
          featuredNews {
            title
            excerpt
            images {
              src {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
              title
            }
            href
          }
          news {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            imageTitle
            href
          }
          headerText
        }
        storySegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
        }
        missionSegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
          detailText
        }
        teamSegment {
          members {
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
              title
            }
            name
            title
            bioInfo {
              name
              value
            }
            contact {
              type
              value
            }
          }
        }
        communitySegment {
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
          }
          headerText
          descriptionText
        }
      }
    }
  }
`

export default HomePage;

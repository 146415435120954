import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Responsive,
} from 'semantic-ui-react';
import Carousel from 'nuka-carousel';
import SliderNextButton from './SliderNextButton';
import SliderPreviousButton from './SliderPreviousButton';

import {
  MAX_MOBILE, MIN_MOBILE
} from '../constants';

export class PagingDots extends React.Component {
  getDotIndexes(slideCount, slidesToScroll, slidesToShow, cellAlign) {
    const dotIndexes = [];
    let lastDotIndex = slideCount - slidesToShow;

    switch (cellAlign) {
      case 'center':
      case 'right':
        lastDotIndex += slidesToShow - 1;
        break;
      default:
    }
    if (lastDotIndex < 0) {
      return [0];
    }

    for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
      dotIndexes.push(i);
    }
    dotIndexes.push(lastDotIndex);
    return dotIndexes;
  }

  getListStyles() {
    return {
      position: 'relative',
      margin: 0,
      // top: -10,
      padding: 0
    };
  }

  getListItemStyles() {
    return {
      listStyleType: 'none',
      display: 'inline-block'
    };
  }

  getButtonStyles(active) {
    return {
      border: 0,
      background: 'transparent',
      color: active ? 'white' : 'black' ,
      cursor: 'pointer',
      padding: 2,
      fontSize: 20,
      opacity: active ? 1 : 0.5
    };
  }

  render() {
    const indexes = this.getDotIndexes(
      this.props.slideCount,
      this.props.slidesToScroll,
      this.props.slidesToShow,
      this.props.cellAlign
    );
    return (
      <ul style={this.getListStyles()}>
        {indexes.map(index => {
          return (
            <li style={this.getListItemStyles()} key={index}>
              <div
                style={this.getButtonStyles(this.props.currentSlide === index)}
                onClick={this.props.goToSlide.bind(null, index)}
                aria-label={`slide ${index + 1} bullet`}
              >
                &bull;
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}


const Advice = styled(({
  text, author, organization,
  className
}) => (
  <React.Fragment>
    <Responsive as={'div'} maxWidth={MAX_MOBILE}>
      <Grid container centered className={className} style={{ margin: '0px !important'}}>
        <Grid.Row centered  className="ad-text" style={{paddingTop: '40px'}}>
          ”{text}”
        </Grid.Row>
        <Grid.Row centered className="ad-author">
          — {author}<br />{organization}
        </Grid.Row>
      </Grid>
    </Responsive>
    <Responsive as={'div'} minWidth={MIN_MOBILE}>
      <Grid container className={className}>
        <Grid.Row centered>
          <Grid.Column width={10} className="ad-text">
            ”{text}”
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={8} className="ad-author">
            — {author}<br />{organization}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Responsive>    
  </React.Fragment>  
))`
  & .ad-text {
    font-size: 30px;
    text-align: center !important;
    line-height: 38px;
    font-weight: 300;
    font-style: italic;
    font-family: 'Open Sans';
    color: #FFFFFF;
    @media (max-width: ${MAX_MOBILE}px) {
      font-size: 20px;
      line-height: 28px;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  & .ad-author {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.85;
    text-align: center !important;
  }

`;

const Advices = styled(({
  advices=[
    {
      text: "In college there’s normally 3 things that you get to choose 2 from: academics, social life, and your health.... the main thing you have to focus on is time management!",
      author: 'Norman',
      organization: "St. John's University",
    },
    {
      text: "If you’re struggling...office hours are the greatest way to get closer to your professor and to talk to them on a more personal level.",
      author: 'Yasmin',
      organization: "CUNY Hunter College",
    },
    {
      text: "I’m really glad I’m here, but I also have to work full-time all the time while I’m here.",
      author: 'Jackie',
      organization: "New York University",
    },
  ],
  className,
}) => {
  return (
    <Carousel className={className}
      wrapAround
      renderBottomCenterControls={props => <PagingDots {...props} />}
      renderCenterLeftControls={props => <SliderPreviousButton {...props} />}
      renderCenterRightControls={props => <SliderNextButton {...props} />}
    >
      {
        advices.map((advice, index) => {
          return <Advice key={index} {...advice} />
        })
      }
    </Carousel>
  );
})`
  & .slider-control-bottomcenter {
    bottom: unset !important;
    margin-top: 10px;
    @media (min-width: ${MIN_MOBILE}px) {
      display: none;
    }

    button {
      padding: 2px !important;
    }
  }

  & .slider-control-centerleft, .slider-control-centerright {
    @media (max-width: ${MAX_MOBILE}px) {
      display: none;
    }
  }
  
  & .slider-control-centerleft, .slider-control-centerright {
    top: 100px !important;
  }
  margin-top: 50px;
  @media (max-width: ${MAX_MOBILE}px) {
    margin-top: 20px;
  }
  &:focus {
    outline: none !important;
  }
`;

export default Advices;

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Button,
  Grid,
  Header,
  Image,
  Divider
} from 'semantic-ui-react';
import cx from 'classnames';
import styles from './page.module.css';
import { EarlyAccessBtn } from './BFHeader';

const SectionHeader = styled(Header)`
  color: ${props => props.clr};
  margin-bottom: 25px;

  @media (min-width: 700px) {
    font-size: 50px;
  }
`;

const MainStory = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #424242;

  @media (min-width: 700px) {
    font-size: 30px;
  }
`;

const MainStoryDescription = styled.p`
  font-size: 16px;
  margin-bottom: 10px !important;
  
  @media (min-width: 700px) {
    font-size: 20px;
    margin-bottom: 0px !important;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 16px;
  margin-top: 10px !important;
  margin-bottom: 5px;
  font-weight: 600;
  color: #424242;

  @media (min-width: 700px) {
    font-size: 20px;

  }
`;

const SectionDescription = styled.p`
  font-size: 14px;
  
  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const CompanyNewsImage = styled(Image)`
  max-width: 100% !important;
  background: white !important;
  margin-top: 30px;
`;

const SubstoryImage = styled(Image)`
  height: 175px !important;
  width: 100% !important;
  object-fit: cover !important;

  @media (min-width: 767px) {
    height: 240px !important;
  }
`;

const CompanyNewsBody = styled(Grid.Column)`
  max-width: 90% !important;

  @media (min-width: 767px) {
    max-width: 100% !important;
  }

  @media (min-width: 1400px) {
    margin-left: 0px !important;

    #left {
      p {
        margin-left: 0px !important;
      }
    }
  }
`;

const LearnMoreBtn = styled(EarlyAccessBtn)`
  color: white !important;

  :hover {
    background-color: white !important;
    color: #15919B !important;
  }
`;

const ReadMoreBtn = styled(Button)`
  font-size: 14px !important;
  color: white !important;
  font-weight: 400 !important;
  padding: 12px 15px !important;
  margin-top: 10px !important;

  :hover {
    background-color: white !important;
    color: #15919B !important;
  }
`;

const NewsGrid = styled.div`
  background-color: #F4F4F4; 
`;


const CompanyNewsSegment = ({
  text,
  featuredNews,
  news,
}) => (
  <NewsGrid>
  <Grid stackable container centered verticalAlign='middle' style={{ padding: '8em 0em' }}>
    <Grid.Row style={{ justifyContent: 'center' }}>
      <SectionHeader as='h2' clr='#CD5B49'>
        {text[0]}
      </SectionHeader>
    </Grid.Row>

    <Grid.Row>
        <Grid.Column width={8}>
          <a href={_.get(featuredNews, 'href')} target="_blank" rel="noopener noreferrer">
            <CompanyNewsImage src={_.get(featuredNews, 'image.src')} alt={_.get(featuredNews, 'image.title')}/>
          </a>
        </Grid.Column>
    </Grid.Row>

    <Grid.Row>
      <MainStory>
        {_.get(featuredNews, 'title')}
      </MainStory>

      <MainStoryDescription>
        {_.get(featuredNews, 'excerpt')}
      </MainStoryDescription>
    </Grid.Row>

    <Grid.Row as='a' href={_.get(featuredNews, 'href')} target="_blank" rel="noopener noreferrer">
      <LearnMoreBtn color="teal" className={cx(styles.btn, styles.signup)}> Read more</LearnMoreBtn>
    </Grid.Row>

    <Divider style={{ marginTop: '30px', marginBottom: '30px' }}/>

    <Grid.Row columns='equal'>
      {
        _.castArray(news || []).map((item, index) => {
          return (
            <Grid.Column key={index}>
              <a href={_.get(item, 'href')} target="_blank" rel="noopener noreferrer">
                <SubstoryImage fluid src={_.get(item, 'image.src')} centered alt={_.get(item, 'image.title')}/>
              </a>
      
              <CompanyNewsBody floated='left'>
                <SectionSubtitle>
                  {_.get(item, 'title')}
                </SectionSubtitle>
      
                <SectionDescription>
                  {_.get(item, 'excerpt')}
                </SectionDescription>
              </CompanyNewsBody>
      
              <Grid.Row as='a' href={_.get(item, 'href')} target="_blank" rel="noopener noreferrer">
                <ReadMoreBtn color='teal' className={cx(styles.btn, styles.signup)} >
                  Read more
                </ReadMoreBtn>
              </Grid.Row>
            </Grid.Column>    
          );
        })
      }
    </Grid.Row>

    {/* <Grid.Row as='a' href='/about'>
      <ReadMoreBtn inverted color='red' >Learn more about us</ReadMoreBtn>
    </Grid.Row> */}
  </Grid>
  </NewsGrid>

);

export default CompanyNewsSegment;
import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Image,
  Responsive,
} from 'semantic-ui-react';
import Carousel from 'nuka-carousel';
import SliderNextButton from './SliderNextButton';
import SliderPreviousButton from './SliderPreviousButton';
import PagingDots from './PagingDots2';

import {
  MAX_MOBILE, MIN_MOBILE
} from '../constants';

const ScreenImage = styled(Image)`
  height: 600px;
`;

const Screenshot = styled(({
  screenIndex,
  subtitle,
  description,
  className,
  screen,
  alt,
}) => (
  <React.Fragment>
    <Responsive as={'div'}>
      <Grid container centered stackable className={className} id="screenshot">
        <Grid.Row  className="end-col">
          <ScreenImage src={screen} alt={alt}/>
        </Grid.Row>
        <Grid.Row centered className="start-col">
          <div className="text0">
            <div className="label">
              {screenIndex}
            </div>
          </div>
          <div className="text1">
            {subtitle}
          </div>
        </Grid.Row>
        <Grid.Row centered className="start-col">
          <div className="text2">
            {description}
          </div>
        </Grid.Row>
      </Grid>
    </Responsive>
  </React.Fragment>
))`
  margin: auto;
  &#screenshot {
    .start-col {
      @media (max-width: ${MAX_MOBILE}px) {
        padding: 0 25px;
      }
      @media (min-width: ${MIN_MOBILE}px) {
        transform: translateY(-30px);
      }
      .text0 {
        .label {
          border-radius: 500rem;
          text-align: center;
          min-width: 2rem;
          min-height: 2rem;
          background-color: #FFFFFF;
          display: inline-block;
          color: #15919B;
          line-height: 2rem;
          margin-bottom: 10px;
          display: none;
          @media (max-width: ${MAX_MOBILE}px) {
          }
        }
      }
      .text1 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        @media (min-width: ${MAX_MOBILE}px) {
          font-size: 20px;  
        }
      }
      .text2 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        max-width: 80vw;
        @media (min-width: ${MAX_MOBILE}px) {
          font-size: 16px;  
        }
      }
    
    }
    .end-col {
      @media (max-width: ${MAX_MOBILE}px) {
      }
    }
    @media (max-width: ${MAX_MOBILE}px) {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  height: 300px;
`;


const Screenshots = styled(({
  screenshots,
  className,
}) => {
  return (
    <Carousel
      transitionMode="fade"
      wrapAround
      autoplayInterval={3000}
      className={className}
      renderBottomCenterControls={props => <PagingDots {...props} />}
      renderCenterLeftControls={props => <SliderPreviousButton {...props} />}
      renderCenterRightControls={props => <SliderNextButton {...props} />}
    >
      {
        screenshots.map((screenshot, index) => {
          return <Screenshot key={index} {...screenshot} screenIndex={`${index + 1}`} />
        })
      }
    </Carousel>
  );
})`
  & .slider-list {
    height: 800px !important;
    @media (max-width: ${MAX_MOBILE}px) {
      height: 800px !important;
    }
  }
  & .slider-control-bottomcenter {
    @media (min-width: ${MIN_MOBILE}px) {
    }
    bottom: 195px !important;

    @media (max-width: ${MAX_MOBILE}px) {
      font-size: 20px;
      width: 100vw;
    }
    bottom: 220px !important;
  }
  & .slider-control-centerleft, .slider-control-centerright {
    display: none;
  }
  margin-top: 20px;
  &:focus {
    outline: none !important;
  }
`;

export default Screenshots;

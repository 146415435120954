import React from 'react';
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import Advices from './Advices';
import Screenshots from './ScreenShots';
import AdviceBackground from '../static/images/triangle-blue.svg';

import { SectionHeader, SectionDescription } from './WhySegment';

const HowItWorksSegment = ({
  text,
  screenshots,
  advices,
}) => (
  <div>
    <Segment style={{ padding: '5.7em 0em 1em 0em', background: '#15919B', color: 'white', borderBottom: 'none' }} vertical>
      <Grid container centered stackable verticalAlign='middle'>
        <Grid.Row>
          <SectionHeader as='h2' clr='#FFFFFF'>
          {text[0]}
          </SectionHeader>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <SectionDescription style={{ textAlign: 'center', fontSize: '20px', marginTop: 20 }}>
              {text[1]}
            </SectionDescription>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Screenshots screenshots={screenshots} />
        </Grid.Row>
        </Grid>
      </Segment>
    <Segment style={{ padding: '0em 0em 4.7em 0em', background: '#15919B', color: 'white', backgroundImage: `url(${AdviceBackground})`, backgroundSize: 'cover'  }} vertical>
      <Grid container centered stackable verticalAlign='middle' style={{ }}>
        <Grid.Row>
          <SectionHeader as='h2' clr='#FFFFFF'>
            Honest advice only
          </SectionHeader>
        </Grid.Row>
        <Grid.Row>
          <Advices advices={advices}/>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>
);

export default HowItWorksSegment;
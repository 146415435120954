import React from 'react'

// import 'semantic-ui-less/semantic.less';
import ScrollUpButton from 'react-scroll-up-button';

import './styles';

import BFHeader from './BFHeader';
import Footer from './Footer';
import Meta from './Meta';

export const HomeLayout = ({ headerText, children, ...props }) => (
  <div>
    <Meta/>
    <BFHeader text={headerText} />
     {children}
    <Footer />
    <ScrollUpButton />
  </div>
);

export default HomeLayout

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Button,
  Grid,
  Image,
  Container,
} from 'semantic-ui-react';
import { SectionHeader, SectionSubtitle, SectionDescription } from './WhySegment';
import SignUpForm from './SignUpForm';

const TealSignUpBtn = styled(Button)`
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #15919B !important;
  border: 1.5px solid #15919B !important;
  padding: 16px 60px !important;
  border-radius: 23px;
  display: inline-block;

  :hover {
    background-color: #15919B !important;
    color: white !important;
  }

  @media (min-width: 1000px) {
    float: right;
    margin-top: 15px;
  }
`;

const ParentEducatorSegment = ({
  text,
  sections,
  images,
}) => (
  <Container style={{ padding: '8em 0em' }} >
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row style={{ justifyContent: 'center' }}>
        <Grid.Column width={8}>
          <SectionHeader as='h2' clr='#15919B'>
            {text[0]}
          </SectionHeader>
          <SectionDescription style={{whiteSpace: 'pre-line'}}>
            {text[1]}
            {
              sections.map(({subtitle, description}, index) => (
                <React.Fragment key={index}>
                  <SectionSubtitle as='h4' style={{ fontWeight: 400 }}>
                    {subtitle}
                  </SectionSubtitle>
                  <SectionDescription style={{whiteSpace: 'pre-line'}}>
                    {description}
                  </SectionDescription>
                </React.Fragment>
              ))
            }
          </SectionDescription>
            <SignUpForm trigger={
              <TealSignUpBtn inverted >Sign up now</TealSignUpBtn>
            } />
        </Grid.Column>
        <Grid.Column width={6} floated='right'>
          <Image size='large' src={_.get(images, '0.src')} alt={_.get(images, '0.title')}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

export default ParentEducatorSegment;
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Grid,
  Header,
  Image
} from 'semantic-ui-react';
import why1 from '../static/images/why-1.png';
import why2 from '../static/images/why-2.png';
import why3 from '../static/images/why-3.png';
import { LogInBtn } from './TabletDesktopNavigation';

const SectionHeader = styled(Header)`
  color: ${props => props.clr};
  margin-bottom: 25px;

  @media (min-width: 700px) {
    font-size: 50px;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 20px;
  font-weight: 300;

  @media (min-width: 700px) {
    font-size: 30px;
  }
`;

const SectionDescription = styled.p`
  font-size: 16px;
  
  @media (min-width: 700px) {
    font-size: 20px;
  }
`;

const WhyImage = styled(Image)`
  max-width: 70% !important;

  @media (min-width: 767px) {
    max-width: 100% !important;
  }
`;

const WhyBody = styled(Grid.Column)`
  max-width: 90% !important;

  @media (min-width: 767px) {
    max-width: 100% !important;
  }

  @media (min-width: 1400px) {
    margin-left: 0px !important;

    #left {
      p {
        margin-left: 0px !important;
      }
    }
  }
`;

const WhyRow = styled(Grid.Row)`
  max-width: 1143px !important;
`;

const LearnMoreBtn = styled(LogInBtn)`
  background-color: transparent !important;
`;

const WhySegment = ({
  text = [
    `Why BestFit?`,
  ],
  sections = [
    {
      src: why1,
      subtitle: `Searching for colleges should be one of the most exciting and engaging moments of your student life.`,
      description: `It’s a chance to learn more about who you are and what you care about in your future college and career path.`
    },
    {
      src: why2,
      subtitle: `However, it’s overwhelming, frustrating, and costly to find colleges that are a good fit for you.`,
      description: `College websites and brochures all look the same, college visits are expensive, and college rankings don’t portray the full benefits and challenges of a college experience.`,
    },
    {
      src: why3,
      subtitle: `BestFit makes college search fun, simple, and equitable.`,
      description: `We believe everyone deserves to find colleges that are an academic, social, and financial fit, and provide students with the resources and insights to do so.`,
    },
  ],
}) => (
  <Grid stackable container centered verticalAlign='middle' style={{ padding: '8em 0em' }}>
    <Grid.Row style={{ justifyContent: 'center' }}>
      <SectionHeader as='h2' clr='#CD5B49'>
        {text[0]}
      </SectionHeader>
    </Grid.Row>

    <WhyRow reversed='tablet computer' >
      <Grid.Column floated='right' width={6}>
        <WhyImage src={_.get(sections, '0.src')} floated='right'/>
      </Grid.Column>

      <WhyBody floated='left' width={8}>
        <SectionSubtitle>
          {_.get(sections, '0.subtitle')}
        </SectionSubtitle>
        <SectionDescription>
          {_.get(sections, '0.description')}
        </SectionDescription>
      </WhyBody>
    </WhyRow>

    <WhyRow>
      <Grid.Column floated='left' width={6}>
        <WhyImage src={_.get(sections, '1.src')} />
      </Grid.Column>

    <WhyBody floated='right' width={8}>
        <SectionSubtitle>
          {_.get(sections, '1.subtitle')}
        </SectionSubtitle>

        <SectionDescription>
          {_.get(sections, '1.description')}
        </SectionDescription>
      </WhyBody>
    </WhyRow>

    <WhyRow reversed='tablet computer'>
      <Grid.Column floated='right' width={6}>
        <WhyImage floated='right' src={_.get(sections, '2.src')} />
      </Grid.Column>

      <WhyBody width={8}>
        <SectionSubtitle>
          {_.get(sections, '1.subtitle')}
        </SectionSubtitle>

        <SectionDescription>
          {_.get(sections, '1.description')}
        </SectionDescription>
      </WhyBody>
    </WhyRow>

    <Grid.Row as='a' href='/about'>
      <LearnMoreBtn inverted color='red' >Learn more about us</LearnMoreBtn>
    </Grid.Row>
  </Grid>
);

export default WhySegment;
export { SectionHeader, SectionSubtitle, SectionDescription };